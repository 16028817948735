/* Tailwind CSS */
@layer tailwind-base, primeng, tailwind-utilities;

/* Primeng Settings */
@import 'primeicons/primeicons.css';

/* Leaflet */
@import 'leaflet/dist/leaflet.css';

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

html {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
}

body {
    margin: 0;
    padding: 0;
    background-image: url(/images/background.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a:not(.p-menuitem-link),
a:not(.p-menuitem-link):visited {
    color: var(--primary-color);
    text-decoration: none;
}

.p-button:not(.p-button-text) {
    color: white;
}

.p-menuitem-link:visited {
    color: inherit;
}

.p-messages .p-message {
    margin: 0;
}

.p-messages .p-message.p-message-info {
    color: #ffffff;
    background: rgba(59, 130, 246, 0.46);
    border: 1px solid rgba(29, 78, 216, 0.66);
}

.p-messages .p-message.p-message-success {
    color: #ffffff;
    background: rgba(34, 197, 94, 0.46);
    border: 1px solid rgba(21, 128, 61, 0.66);
}

.p-messages .p-message.p-message-warn {
    color: #ffffff;
    background: rgba(234, 179, 8, 0.46);
    border: 1px solid rgba(161, 98, 7, 0.66);
}

.p-messages .p-message.p-message-error {
    color: #ffffff;
    background: rgba(239, 68, 68, 0.46);
    border: 1px solid rgba(185, 28, 28, 0.66);
}

.p-messages .p-message.p-message-info .p-message-icon,
.p-messages .p-message.p-message-success .p-message-icon,
.p-messages .p-message.p-message-warn .p-message-icon,
.p-messages .p-message.p-message-error .p-message-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
}

.p-message .p-message-detail .code {
    padding: 2px 5px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
}

.p-toast .p-toast-message .p-toast-message-icon {
    margin-top: 0.2rem;
}

.p-datatable .p-datatable-header {
    background: none;
}

.p-datatable .p-datatable-thead > tr > th {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
}

.p-datatable .p-datatable-tbody > tr,
.p-paginator {
    background: none;
}

.p-datatable .p-datatable-tbody > tr > td {
    border-color: rgba(255, 255, 255, 0.2);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #60a5fa;
    border-color: #60a5fa;
    color: var(--text-color);
}

.p-tablist .p-tablist-tab-list {
    background: none;
}

.p-tablist .p-tab .pi {
    display: flex;
    align-items: center;
}

.p-tablist .p-tab .pi::before {
    font-size: 16px;
    padding: 2px;
}

.p-paginator {
    border-bottom: none;
}

.p-tabview .p-tabview-panels {
    padding: 0;
}

.p-fileupload .p-progressbar {
    display: none;
}

.p-dialog .p-dialog-header-icons .p-dialog-header-close {
    margin-left: 1rem;
}

.p-selectbutton .p-button.p-highlight::before,
.p-togglebutton.p-highlight .p-button-label,
.p-togglebutton.p-highlight:has(.p-button-icon) .p-button-icon {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.p-togglebutton:has(.p-button-icon) .p-button-icon::before {
    padding-left: 5px;
}

.p-selectbutton .p-button.p-highlight.p-disabled::before,
.p-togglebutton.p-disabled .p-button-label,
.p-togglebutton.p-disabled:has(.p-button-icon) .p-button-icon {
    background: #27272a;
    border-color: #27272a;
}

.emoji-overlay.p-overlaypanel .p-overlaypanel-content {
    padding: 0;
}

.emoji-dialog {
    border-radius: 5px;
}

.emoji-mart-emoji {
    vertical-align: top;
}
